import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaLinkedinIn } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";

const GetNewUpdates = () => {
  const { t, i18n } = useTranslation();

  let validateSchema = Yup.object({
    email: Yup.string().email("Invalid email address"),
  });

  const handleSave = async (values) => {
    try {
      await validateSchema.validate(values, { abortEarly: false });

      const valuesToSend = {
        email: values?.email,
      };

      axios.post("testapi/getnew", valuesToSend).then((res) => {
        if (res && res.status) {
          toast.success(res?.message, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 3000,
            progress: undefined,
          });
          // add to local storage
        } else {
          toast.error(res?.message, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 3000,
            progress: undefined,
          });
        }
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
      } else {
      }
    }
  };

  return (
    <section
      className="w-full mb-12 container mx-auto"
      dir={i18n.language === "en" ? "ltr" : "rtl"}
    >
      <div className="lg:flex justify-between items-center">
        <p className="lg:text-3xl text-2xl text-center font-bold text-text">
          {t("getUpdates.title")}
        </p>
        <div className="rounded-2xl py-2 md:pb-2 pb-6 bg-[#E6E6E6] flex justify-center items-center lg:mt-0 mt-8 lg:w-fit">
          <form
            action="#"
            className="flex justify-center items-center pt-4 px-4 xl:pb-4 lg:pb-4 md:pb-4 pb-0 lg:w-fit w-full"
          >
            <div className="lg:w-96 w-full">
              <input
                name="email"
                type="email"
                placeholder={t("getUpdates.placeholder")}
                required
                className="text-right rounded-tr-xl rounded-br-xl border-none py-4 px-4 w-full"
                style={{ margin: 0 }}
              />
            </div>
            <button
              type="submit"
              className="py-4 lg:px-12 px-6 lg:w-fit md:w-fit   rounded-tl-xl rounded-bl-xl text-white font-bold bg-[#28A4A8] hover:bg-[#218e92]"
            >
              {t("getUpdates.submit")}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default GetNewUpdates;
