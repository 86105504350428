import React from "react";
import { Helmet } from "react-helmet";
import WhatIsEvent from "../Components/WhatIsEvent/WhatIsEvent";
import DigitalTransformation from "../Components/DigitalTransformation/DigitalTransformation";
import CoOperation from "../Components/CoOperation/CoOperation";
import WhyThisEvent from "../Components/WhyThisEvent/WhyThisEvent";
import Creativity from "../Components/Creativity/Creativity";
import Sponser from "../Components/Sponser/Sponser";
import EventDetails from "../Components/EventDetails/EventDetails";
import RegisterWithUs from "../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../Components/GetNewUpdates/GetNewUpdates";
import Ministry_Of_Tourism from "../Components/Ministry_Of_Tourism/Ministry_Of_Tourism";
import { useTranslation } from "react-i18next";
import HomeSection from "../Components/HomeSection";
import MinisterSection from "../Components/MinisterSection";
import Banner from "../Components/Banner";
import RegisterBanner from "../Components/RegisterBanner";
import DontMissBanner from "../Components/DontMissBanner";
import FlipCards from "../Components/FlipCards";
import VideoSection from "../Components/VideoSection";

const Home = () => {
  const { i18n, t } = useTranslation();

  return (
    <main dir={i18n.language === "en" ? "ltr" : "rtl"}>
      <Helmet>
        <title>{t("home.title")}</title>
      </Helmet>
      <section className="relative">
        <HomeSection />
        {/* <SponserMinister /> */}
        <WhatIsEvent />
        <Ministry_Of_Tourism />
        {/* <MinisterSection /> */}
        <Banner />
        <DigitalTransformation />
        <CoOperation />
        <Creativity />
        <RegisterBanner />
        <FlipCards />
        <DontMissBanner />
        <VideoSection />
        <GetNewUpdates />
      </section>
    </main>
  );
};

export default Home;
